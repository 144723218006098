import { faBarsStaggered, faHouseChimneyWindow, faPersonRunning, faPersonShelter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    const [sidebar, setSidebar]=useState(false);
    return (
        <Fragment>
            
        </Fragment>
    );
}
export default Footer;