import React from 'react'
import { Link } from 'react-router-dom'

const Configuration = () => {
    return (
        <div className='configWRapper'>
            <div className='configHead'>
                <h2>Configuration</h2>
            </div>
            <div className='table_wrapper'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Utility</th>
                            <th>Building wide</th>
                            <th>Submeter</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Electricity</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>Electricity</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>Electricity</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Configuration
