import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import React from 'react';
import { Link } from 'react-router-dom';

const HomePage = () => {
    return (
        <div className='dashboardWRp'>
            <div className='container-fluid'>
                <div className='dashprofileWrp'>
                    <div className='row align-items-center'>
                        <div className='col-8'>
                            <div className='profInoImgWrp gap-3 d-flex align-items-center'>
                                <div className='profCotn'>
                                    <h3>Hi, Jimmy</h3>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className='col-4'>
                            <div className="prfbtngrp d-flex justify-content-end">
                                <Dropdown as={ButtonGroup}>
                                    <Button className='btnAddML btn-md'>Add</Button>
                                    <Dropdown.Toggle split variant="primary" className='btn-md' />
                                    <Dropdown.Menu className="super-colors">
                                        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                                        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                                        <Dropdown.Item eventKey="3" active>
                                            Active Item
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className='tablCountWrp d-flex gap-3'>
                    <div className='countrwp'>
                        <h5>Total buildings</h5>
                        <h2 className='mb-2'>14</h2>
                        <Link to="" className='btn-sm'>Add building</Link>
                    </div>
                    <div className='countrwp'>
                        <h5>Total meters</h5>
                        <h2 className='mb-2'>48</h2>
                        <Link to="" className='btn-sm'>Add meter</Link>
                    </div>
                    <div className='countrwp'>
                        <h5>Total buildings</h5>
                        <h2>14</h2>
                    </div>
                </div>
                <div className='table_wrapper'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Sqft</th>
                                <th>Building type</th>
                                <th>Carbon emissions</th>
                                <th>Energy Star score</th>
                                <th>Meters connected</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <b>Forte Condominium</b> <br />
                                    230 Ashland Place
                                </td>
                                <td>130,213 sqft</td>
                                <td>Mixed use</td>
                                <td>245,865 kgC02e</td>
                                <td>C | 75</td>
                                <td>3/3 online</td>
                                <td>
                                    <div className='tbleAction'>
                                        <Link to="/building-details">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.98296 6.98349L14.811 11.8116C14.861 11.8615 14.8891 11.9293 14.8891 12C14.8891 12.0707 14.861 12.1385 14.811 12.1884L9.98291 17.0165" stroke="#5C5C5C" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </Link>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Forte Condominium</b> <br />
                                    230 Ashland Place
                                </td>
                                <td>130,213 sqft</td>
                                <td>Mixed use</td>
                                <td>245,865 kgC02e</td>
                                <td>C | 75</td>
                                <td>3/3 online</td>
                                <td>
                                    <div className='tbleAction'>
                                        <Link to="/building-details">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.98296 6.98349L14.811 11.8116C14.861 11.8615 14.8891 11.9293 14.8891 12C14.8891 12.0707 14.861 12.1385 14.811 12.1884L9.98291 17.0165" stroke="#5C5C5C" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </Link>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Forte Condominium</b> <br />
                                    230 Ashland Place
                                </td>
                                <td>130,213 sqft</td>
                                <td>Mixed use</td>
                                <td>245,865 kgC02e</td>
                                <td>C | 75</td>
                                <td>3/3 online</td>
                                <td>
                                    <div className='tbleAction'>
                                        <Link to="/building-details">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.98296 6.98349L14.811 11.8116C14.861 11.8615 14.8891 11.9293 14.8891 12C14.8891 12.0707 14.861 12.1385 14.811 12.1884L9.98291 17.0165" stroke="#5C5C5C" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </Link>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Forte Condominium</b> <br />
                                    230 Ashland Place
                                </td>
                                <td>130,213 sqft</td>
                                <td>Mixed use</td>
                                <td>245,865 kgC02e</td>
                                <td>C | 75</td>
                                <td>3/3 online</td>
                                <td>
                                    <div className='tbleAction'>
                                        <Link to="/building-details">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.98296 6.98349L14.811 11.8116C14.861 11.8615 14.8891 11.9293 14.8891 12C14.8891 12.0707 14.861 12.1385 14.811 12.1884L9.98291 17.0165" stroke="#5C5C5C" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </Link>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Forte Condominium</b> <br />
                                    230 Ashland Place
                                </td>
                                <td>130,213 sqft</td>
                                <td>Mixed use</td>
                                <td>245,865 kgC02e</td>
                                <td>C | 75</td>
                                <td>3/3 online</td>
                                <td>
                                    <div className='tbleAction'>
                                        <Link to="/building-details">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.98296 6.98349L14.811 11.8116C14.861 11.8615 14.8891 11.9293 14.8891 12C14.8891 12.0707 14.861 12.1385 14.811 12.1884L9.98291 17.0165" stroke="#5C5C5C" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </Link>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Forte Condominium</b> <br />
                                    230 Ashland Place
                                </td>
                                <td>130,213 sqft</td>
                                <td>Mixed use</td>
                                <td>245,865 kgC02e</td>
                                <td>C | 75</td>
                                <td>3/3 online</td>
                                <td>
                                    <div className='tbleAction'>
                                        <Link to="/building-details">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.98296 6.98349L14.811 11.8116C14.861 11.8615 14.8891 11.9293 14.8891 12C14.8891 12.0707 14.861 12.1385 14.811 12.1884L9.98291 17.0165" stroke="#5C5C5C" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </Link>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Forte Condominium</b> <br />
                                    230 Ashland Place
                                </td>
                                <td>130,213 sqft</td>
                                <td>Mixed use</td>
                                <td>245,865 kgC02e</td>
                                <td>C | 75</td>
                                <td>3/3 online</td>
                                <td>
                                    <div className='tbleAction'>
                                        <Link to="/building-details">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.98296 6.98349L14.811 11.8116C14.861 11.8615 14.8891 11.9293 14.8891 12C14.8891 12.0707 14.861 12.1385 14.811 12.1884L9.98291 17.0165" stroke="#5C5C5C" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </Link>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Forte Condominium</b> <br />
                                    230 Ashland Place
                                </td>
                                <td>130,213 sqft</td>
                                <td>Mixed use</td>
                                <td>245,865 kgC02e</td>
                                <td>C | 75</td>
                                <td>3/3 online</td>
                                <td>
                                    <div className='tbleAction'>
                                        <Link to="/building-details">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.98296 6.98349L14.811 11.8116C14.861 11.8615 14.8891 11.9293 14.8891 12C14.8891 12.0707 14.861 12.1385 14.811 12.1884L9.98291 17.0165" stroke="#5C5C5C" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </Link>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Forte Condominium</b> <br />
                                    230 Ashland Place
                                </td>
                                <td>130,213 sqft</td>
                                <td>Mixed use</td>
                                <td>245,865 kgC02e</td>
                                <td>C | 75</td>
                                <td>3/3 online</td>
                                <td>
                                    <div className='tbleAction'>
                                        <Link to="/building-details">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.98296 6.98349L14.811 11.8116C14.861 11.8615 14.8891 11.9293 14.8891 12C14.8891 12.0707 14.861 12.1385 14.811 12.1884L9.98291 17.0165" stroke="#5C5C5C" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>

                                        </Link>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default HomePage;