import React from 'react'
import ElectricityCart from '../component/charts/electricityCart'

const ElectricityDetails = () => {
    return (
        <div className='electryWRapr'>
            <div className='dashprofileWrp'>
                <div className='container-fluid'>
                    <div className='build_back mb-0'>
                        <a>
                            <svg className='mx-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_3510_24756)">
                                    <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" fill="#253338" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_3510_24756">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            Forte Condominium
                        </a>
                    </div>
                </div>
            </div>
            <div className='electGrapWRapper'>
                <ElectricityCart />
                
            </div>
        </div>
    )
}

export default ElectricityDetails
