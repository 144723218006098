import React, { Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';

const Typography = () => {
    return (
        <Fragment>
            <div className='dashprofileWrp'>
                <div className='container-fluid'>
                    <div className='typographyWRapp'>
                        <div className='dashboardWRp'>
                            <div className='row align-items-center'>
                                <div className='col-12'>
                                    <div className='profInoImgWrp gap-3 d-flex '>
                                        <div className='prfImg'>
                                            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" fill="white" />
                                                <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="#E5E5E5" />
                                                <path d="M20.3 37H24.052V19.92H29.624V16.98H14.728V19.92H20.3V37ZM36.7642 37.252C37.8002 37.252 38.5282 37.084 39.0602 36.888V34.2C38.5842 34.396 38.0802 34.48 37.4082 34.48C36.3442 34.48 35.7282 33.892 35.7282 32.604V24.904H38.9482V22.356H35.7282V19.136H32.3402V22.356H30.3802V24.904H32.3402V32.912C32.3402 35.684 33.8522 37.252 36.7642 37.252Z" fill="#5B3E77" />
                                            </svg>

                                        </div>
                                        <div className='profCotn'>
                                            <h2>Typography - Desktop</h2>
                                            <h5>The Cotocon Group</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="configHead">
                <h6>Desktop</h6>
            </div>
            <div className='dashboardWRp'>
                <div className='container-fluid'>
                    <div className='table_wrapper'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Typestyle</th>
                                    <th>Typeface</th>
                                    <th>Size</th>
                                    <th>Line-height</th>
                                    <th>Weight</th>
                                    <th>Color</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <h1 className='large'>Display</h1>
                                    </td>
                                    <td>Graphik</td>
                                    <td>40px</td>
                                    <td>52px</td>
                                    <td>Semibold</td>
                                    <td>$blue-extra-dark-text</td>
                                </tr>
                                <tr>
                                    <td>
                                        <h1>Heading 1</h1>
                                    </td>
                                    <td>Graphik</td>
                                    <td>32px</td>
                                    <td>40px</td>
                                    <td>Semibold</td>
                                    <td>$blue-extra-dark-text</td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2>Heading 2</h2>
                                    </td>
                                    <td>Graphik</td>
                                    <td>24px</td>
                                    <td>32px</td>
                                    <td>Semibold</td>
                                    <td>$blue-extra-dark-text</td>
                                </tr>
                                <tr>
                                    <td>
                                        <h3>Heading 3</h3>
                                    </td>
                                    <td>Graphik</td>
                                    <td>20px</td>
                                    <td>28px</td>
                                    <td>Medium</td>
                                    <td>$blue-extra-dark-text</td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Heading 4</h4>
                                    </td>
                                    <td>Graphik</td>
                                    <td>18px</td>
                                    <td>26px</td>
                                    <td>Medium</td>
                                    <td>$blue-extra-dark-text</td>
                                </tr>
                                <tr>
                                    <td>
                                        Body
                                    </td>
                                    <td>Graphik</td>
                                    <td>18px</td>
                                    <td>26px</td>
                                    <td>Medium</td>
                                    <td>$blue-extra-dark-text</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Body Bold</b>
                                    </td>
                                    <td>Graphik</td>
                                    <td>18px</td>
                                    <td>26px</td>
                                    <td>Medium</td>
                                    <td>$blue-extra-dark-text</td>
                                </tr>
                                <tr>
                                    <td>
                                        <a>Link</a>
                                    </td>
                                    <td>Graphik</td>
                                    <td>18px</td>
                                    <td>26px</td>
                                    <td>Regular</td>
                                    <td>$blue-text-link</td>
                                </tr>
                                <tr>
                                    <td>
                                        Table numbers
                                    </td>
                                    <td>Graphik</td>
                                    <td>18px</td>
                                    <td>26px</td>
                                    <td>Regular</td>
                                    <td>$blue-extra-dark-text</td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>Label</label>
                                    </td>
                                    <td>Graphik</td>
                                    <td>16px</td>
                                    <td>24px</td>
                                    <td>Medium</td>
                                    <td>$gray-dark</td>
                                </tr>
                                <tr>
                                    <td>
                                        <small>Body small</small>
                                    </td>
                                    <td>Graphik</td>
                                    <td>16px</td>
                                    <td>24px</td>
                                    <td>Regular</td>
                                    <td>$gray-dark</td>
                                </tr>
                                <tr>
                                    <td>
                                        <small><b>Body small bold</b></small>
                                    </td>
                                    <td>Graphik</td>
                                    <td>16px</td>
                                    <td>24px</td>
                                    <td>Medium</td>
                                    <td>$gray-dark</td>
                                </tr>
                                <tr>
                                    <td>
                                        <caption>Caption</caption>
                                    </td>
                                    <td>Graphik</td>
                                    <td>14px</td>
                                    <td>20px</td>
                                    <td>Regular</td>
                                    <td>$gray-dark</td>
                                </tr>
                                <tr>
                                    <td>
                                        <caption><b>Caption bold</b></caption>
                                    </td>
                                    <td>Graphik</td>
                                    <td>14px</td>
                                    <td>20px</td>
                                    <td>Medium</td>
                                    <td>$gray-dark</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Typography
