import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const ElectricityCart = () => {
    const [timeRange, setTimeRange] = useState('currentMonth');

    const handleTimeRangeChange = (e) => {
        setTimeRange(e.target.value);
    };

    const getData = () => {
        switch (timeRange) {
            case 'currentMonth':
                const last30DaysLabels = [];
                const last30DaysData = [];
                for (let i = 29; i >= 0; i--) {
                    last30DaysLabels.push(i + 1); // Get the day of the month
                    last30DaysData.push(Math.floor(Math.random() * 15000)); // Random example data
                }
                return {
                    data: last30DaysData.reverse(),
                    labels: last30DaysLabels.reverse()
                };
            case 'last6Months':
                return {
                    data: [45000, 55000, 60000, 50000, 70000, 80000],
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']
                };
            case 'lastYear':
                return {
                    data: [30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 90000, 80000, 70000, 60000],
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                };
            case 'last5Years':
                return {
                    data: [100000, 90000, 80000, 70000, 60000],
                    labels: ['2019', '2020', '2021', '2022', '2023']
                };
            default:
                return { data: [], labels: [] };
        }
    };

    const { data: chartData, labels: chartLabels } = getData();
    const data = {
        labels: chartLabels,
        datasets: [
            {
                label: '',
                data: chartData,
                backgroundColor: '#54558B', // Set the bar color here
                borderColor: '#54558B', // Set the border color here
                borderWidth: 1,
                barThickness: 10, // Set max column width here
            },
        ],
    };

    const options = {
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false, // Remove vertical grid lines
                },
            },
            y: {
                ticks: {
                    callback: (value) => {
                        return value >= 1000 ? `${value / 1000}k` : value;
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false, // Completely hide the legend
            },
            title: {
                display: false,
                text: 'Energy Consumption Data',
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const { dataset, parsed } = tooltipItem;
                        const dateLabel = tooltipItem.label; // Assuming the label is the date
                        const value = parsed.y; // Get the Y value
                        return `${dateLabel}, ${value.toLocaleString()} kWh`; // Format the tooltip
                    },
                },
            },
        },
    };
    return (
        <div className='electCharWRapper'>
            <div className='elechEADWRp d-flex justify-content-between gap-5'>
                <div className='electLeft'>
                    <h2>Electricity</h2>
                    <small>Usage</small>
                    <h3>123,456 kWh</h3>
                </div>
                <div className='electRgiht'>
                    <div className='dropdChangChart'>
                        <label>Timeframe</label>
                        <select value={timeRange} onChange={handleTimeRangeChange}>
                            <option value="currentMonth">This month</option>
                            <option value="last6Months">Last 6 Months</option>
                            <option value="lastYear">Last 1 Year</option>
                            <option value="last5Years">Last 5 Years</option>
                        </select>
                    </div>

                </div>
            </div>
            <div className='chartWRpbox'>
                <Bar data={data} options={options} />
            </div>
        </div>
    )
}

export default ElectricityCart
