import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import ProgressBarMultiscore from '../progress-bar/progress-bar-multiscore';
import ProgressBar from '../progress-bar/progress-bar';

const Overview = () => {
    return (
        <div className='tabBodyWrp'>
            <div className='enery_carbon_wrp d-flex'>
                <div className='enery_wrp'>
                    <h4>Energy Star score</h4>
                    <ProgressBarMultiscore value={53} labels={[
                        { name: 'D', partition: 55, color: '#ED4833', width: '55%' },
                        { name: 'C', partition: 70, color: '#F6B135', width: '15%' },
                        { name: 'B', partition: 85, color: '#F5D738', width: '15%' },
                        { name: 'A', partition: 100, color: '#39B085', width: '15%' }
                    ]} />

                </div>
                <div className='carbon_wrp'>

                    <h4>Energy Star score</h4>
                    <ProgressBar percent="32%" value={'106,446'} total="354,823" unit='kgCo2e' />

                </div>
            </div>
            <div className='utilistWrp'>
                <div className='ulsitHead'>
                    <h4>Utilities this year</h4>
                </div>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <h4>Electricity</h4>
                            <h5>Usage</h5>
                            <h3>123,456 kWh</h3>
                        </Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <h4>Gas</h4>
                            <h5>Usage</h5>
                            <h3>224,567 CCF</h3>
                        </Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <h4>Water</h4>
                            <h5>Usage</h5>
                            <h3>321,432 gal</h3>
                        </Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    )
}

export default Overview
